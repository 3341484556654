const env = String(process?.env?.ENV);

export const Config = {
	Env: {
		Is: env,
		IsLocal: env === 'local',
		IsDev: env === 'dev',
		IsQa: env === 'qa',
		IsStg: env === 'stg',
		IsPreview: env === 'preview',
		IsPrd: env === 'prd',
	} as const,
} as const;
