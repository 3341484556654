'use client';

import { type FC } from 'react';
import Script from 'next/script';
import { Config } from '../../../Config';

const getGoogleAnalyticsSnippet = () => {
	let snippetHead: string = '';
	let snippetBody: JSX.Element = <></>;

	if (Config.Env.IsPrd) {
		snippetHead = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WNR25VVH');
        `;

		snippetBody = (
			<iframe
				src='https://www.googletagmanager.com/ns.html?id=GTM-WNR25VVH'
				height='0'
				width='0'
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		);
	} else {
		snippetHead = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=H4FNsMvE-UyoT33tSnleBw&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WNR25VVH');
        `;

		snippetBody = (
			<iframe
				src='https://www.googletagmanager.com/ns.html?id=GTM-WNR25VVH&gtm_auth=H4FNsMvE-UyoT33tSnleBw&gtm_preview=env-3&gtm_cookies_win=x'
				height='0'
				width='0'
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		);
	}

	return { snippetHead, snippetBody };
};

export const GoogleTagManagerHead: FC = () => {
	const { snippetHead } = getGoogleAnalyticsSnippet();
	return <Script dangerouslySetInnerHTML={{ __html: snippetHead }} />;
};

export const GoogleTagManagerBody: FC = () => {
	const { snippetBody } = getGoogleAnalyticsSnippet();
	return <noscript>{snippetBody}</noscript>;
};
